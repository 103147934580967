<template>
  <section>
    <link
      rel="stylesheet"
      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
    />
    <!--<h2
      style="
        margin-top: 25px;
        margin-bottom: 25px;
        color: #9f9f9f;
        font-size: 31px;
        font-weight: 400;
      "
    >
      <i class="fas fa-hotel" style="margin-right: 10px"></i>
      {{ escola.nome }}
    </h2>-->

    <h2 class="titulo-links-escolha">EDITAR UNIDADE DE ENSINO</h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <label>CNPJ da Escola</label>
            <!-- <pm-InputText  v-model="escola.cnpj" v-mask="{mask: 'NN.NNN.NNN/NNNN-NN', model: 'escola.cnpj' }"  /> -->
            <pm-InputMask v-model="escola.cnpj" mask="99.999.999/9999-99" />
          </div>

          <div class="field col-12 md:col-6">
            <label>Nome da Escola</label>
            <pm-InputText
              v-model="escola.nome"
              v-on:keypress="isLetter($event)"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Código INEP</label>
            <pm-InputText
              v-on:keypress="isNumber($event)"
              v-model="escola.codigo_INEP"
            />
          </div>

          <div class="field col-12 md:col-8">
            <label>Email</label>
            <pm-InputText v-model="escola.email" />
          </div>
          <div class="field col-12 md:col-4">
            <label>Telefone</label>
            <pm-InputText
              v-model="escola.telefone"
              v-mask="{ mask: '(NN) NNNN-NNNN', model: 'escola.telefone' }"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>CEP</label>
            <pm-InputText
              max="8"
              v-model="escola.cep"
              v-on:keypress="isNumber($event)"
              v-mask="{ mask: 'NNNNN-NNN', model: 'escola.cep' }"
              v-on:input="buscarCep()"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Logradouro</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.logradouro"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>Número</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.numero"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label>Complemento</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.complemento"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Bairro/Distrito</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.bairro"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label>Cidade</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.cidade"
            />
          </div>

          <div class="field col-12 md:col-1">
            <label>Estado</label>
            <pm-InputText
              :disabled="cepValido == 0 || cepValido == 2"
              v-model="escola.estado"
            />
          </div>

          <!--<div class="field col-12 md:col-12"></div>
          <div class="field col-12 md:col-2">
            <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
          </div>

          <div class="field col-12 md:col-2" >
            <pm-Button label="Editar"
             v-if="escola.cnpj && escola.nome && escola.codigo_INEP && escola.email && escola.telefone && escola.cep && escola.estado && escola.cidade && escola.bairro && escola.numero && escola.logradouro"
             @click="editar"   />
          </div>-->
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button
            label="Voltar"
            class="p-button-danger"
            icon="pi pi-angle-left"
            iconPos="left"
            @click="voltar()"
          />

          <pm-Button
            class="p-button-success"
            label="Editar"
            icon="pi pi-check"
            iconPos="right"
            v-if="escola.nome"
            @click="editar"
          />
        </div>
      </template>
    </pm-Card>

    <!--<div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('EDITAR UNIDADE DE ENSINO')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="flex md10">
                    <div class="row colegio-info">
                      <div class="flex md3 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px;text-transform: uppercase;">CNPJ</label>
                        <va-input
                          v-model="escola.cnpj"
                          v-if="escolaHab ==1"
                          v-mask="{mask: 'NN.NNN.NNN/NNNN-NN', model: 'escola.cnpj' }"
                        />
                        <va-input
                          v-model="escola.cnpj"
                          disabled
                          v-if="escolaHab ==0"
                          v-mask="{mask: 'NN.NNN.NNN/NNNN-NN', model: 'escola.cnpj' }"
                        />
                      </div>
                      <div class="flex md6 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Nome da escola</label>
                        <va-input
                          v-model="escola.nome"
                          v-if="escolaHab ==1"
                          v-on:keypress="isLetter($event)"
                        />
                        <va-input
                          v-model="escola.nome"
                          disabled
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md2 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Código INEP</label>
                        <va-input
                          maxlength="8"
                          v-on:keypress="isNumber($event)"
                          v-model="escola.codigo_INEP"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          v-model="escola.codigo_INEP"
                          disabled
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md8 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Email</label>
                        <va-input
                          v-model="escola.email"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          disabled
                          v-model="escola.email"
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md4 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Telefone</label>
                        <va-input
                          v-model="escola.telefone"
                          v-mask="{mask: '(NN) NNNN-NNNN', model: 'escola.telefone' }"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          disabled
                          v-model="escola.telefone"
                          v-if="escolaHab ==0"
                        />
                      </div>
                      <div class="flex md2 sm6 xs12" v-if="escolaHab ==1">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">CEP</label>
                        <va-input
                          v-on:keypress="isNumber($event)"
                          type="text"
                          max="8"
                          v-model="escola.cep"
                          v-mask="{mask: 'NNNNN-NNN', model: 'escola.cep' }"
                          v-on:input="buscarCep()"
                        />
                      </div>
                      <div class="flex md2 sm6 xs12" v-if="escolaHab ==0">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">CEP</label>
                         <va-input
                          disabled
                          type="text"
                          max="8"
                          v-model="escola.cep"
                          v-mask="{mask: 'NNNNN-NNN', model: 'escola.cep' }"
                        />
                      </div>

                       <div class="flex md3 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">logradouro</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.logradouro"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.logradouro"
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md2 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Número</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.numero"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.numero"
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md2 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Complemento</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.complemento"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.complemento"
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md3 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Bairro ou Distrito</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.bairro"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.bairro"
                          v-if="escolaHab ==0"
                        />
                      </div>

                       <div class="flex md3 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Cidade</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.cidade"
                          v-if="escolaHab ==1"
                        />
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.cidade"
                          v-if="escolaHab ==0"
                        />
                      </div>

                      <div class="flex md2 sm6 xs12">
                        <label style="color: #9f9f9f;font-size: 13px; text-transform: uppercase;">Estado</label>
                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.estado"
                          v-if="escolaHab ==1"
                        />

                        <va-input
                          :disabled="cepValido == 0 || cepValido == 2 || escolaHab ==0"
                          v-model="escola.estado"
                          v-if="escolaHab ==0"
                        />
                      </div>


                    </div>
                  </div>
                  <div class="flex md2">

                    <div class="row" style="position: absolute;bottom: 0 !important;margin-bottom:20px">
                      <div class="" v-if="escolaHab ==0"></div>
                      <div class="" v-if="escolaHab ==1"></div>

                      <div class="" v-if="escolaHab ==0">
                        <va-button class="mr-2 mb-2"  @click="habilitaredicao" v-if="escolaHab ==0 && situacaoAno"  style="float:right;" :rounded="false"> {{ $t('Habilitar Edição') }}</va-button>
                      </div>

                      <div class=""  v-if="escolaHab ==1">
                        <va-button class="mr-2 mb-2"  @click="escolaHab = 0" v-if="escolaHab ==1" style="float:right;" color="danger" :rounded="false"> {{ $t('Desabilitar Edição') }}</va-button>
                      </div>
                      <div class=""  v-if="escolaHab ==1">
                        <va-button class="mr-2 mb-2"  @click="editar" v-if="escolaHab ==1" style="float:right;" :rounded="false"> {{ $t('Editar') }}</va-button>
                      </div>

                    </div>

                  </div>
                </div>

              </form>
              <div style="margin-top:20px">
                <va-button class="mr-2 mb-2" color="danger" @click="this.$router.push({name: 'escolas'});">Voltar</va-button>
              </div>
            </va-card-content>
          </va-card>
        </div>


      </div>
    </div>-->
  </section>
</template>

<script>
//import { buscarCEP } from "@/class/buscarCEP";
import alertaAnoLetivo from "@/components/alerta_anoletivo.vue";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";
import { Calendario } from "@/class/calendario.js";

export default {
  props: {
    idEscola: {},
  },
  components: {
    alertaAnoLetivo,
  },
  name: "form-elements",
  data() {
    return {
      situacaoAno: 1,
      escolaHab: 0,
      ano: [],
      etapasALL: [],
      periodosALL: [],
      turmasALL: [],
      selectedEtapas: [],
      selectedPeriodos: [],
      escola: {
        id: null,
        cnpj: null,
        nome: null,
        codigo_INEP: null,
        email: null,
        cep: "",
        email: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        logradouro: "",
        telefone: null,
        logradouro: null,
        user_id: store_token_info.usuario_logado,
      },
      cepValido: 1,
      isLoading: false,
      errorMessages: ["Digite um CEP válido!"],
      showTurmaModal: false,
      st_idEscola: null,
      erro: 0,
    };
  },
  methods: {
    voltar() {
      this.$router.push({ name: "escolas" });
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.()-- ]+$/.test(char))
        {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },

    async buscarCalendarioAtual() {
      const data = await Calendario.calendarioAtual();
      this.ano = data.data.ano;
    },

    async listaTudo() {
      const anoSelect = sessionStorage.getItem("anoSelecionado");
      const data = await Escola.obtemInfoEscola(this.st_idEscola, anoSelect);
      this.etapasALL = data.data;
    },

    async buscarPeriodosDaEtapa(etapa_id) {
      this.periodosALL = [];
      const data = await SeriesEscolar.buscaPorSegmento(etapa_id);
      this.periodosALL = data.data;
    },
    async buscarTurmasDoPeriodo(etapa_id, periodo_id, segmento, serie) {
      this.turmasALL = [];
      const novo = {
        ano: this.ano,
        escola_id: this.st_idEscola,
        segmento_id: etapa_id,
        serie_id: periodo_id,
      };
      const data = await Turma.turmasAvancada(novo);

      for (const el of data.data) {
        const novo = {
          id: el.id,
          ano: el.ano,
          escola_id: el.escola_id,
          segmento_id: el.segmento_id,
          serie_id: el.serie_id,
          nome: el.nome,
          turno: el.turno,
          ativa: el.ativa,
          situacao: el.situacao,
          segmento: segmento,
          serie: serie,
        };
        this.turmasALL.push(novo);
      }

      this.showTurmaModal = true;
    },
    async listarPeriodos() {
      this.periodosALL = [];
      for (let i = 0; i < this.selectedEtapas.length; i++) {
        const novo = {
          etapaId: this.selectedEtapas[i].id,
          etapaNome: this.selectedEtapas[i].nome,
          periodosDaEtapa: [],
        };
        const data = await SeriesEscolar.buscaPorSegmento(
          this.selectedEtapas[i].id
        );
        for (let j = 0; j < data.data.length; j++) {
          data.data[j].etapaNome = this.selectedEtapas[i].nome;
          novo.periodosDaEtapa.push(data.data[j]);
        }
        this.periodosALL.push(novo);
      }
    },
    async listarTurmas(periodo) {
      this.turmasALL = [];
      for (let i = 0; i < this.selectedPeriodos.length; i++) {
        const novo1 = {
          ano: this.ano,
          escola_id: this.st_idEscola,
          segmento_id: this.selectedPeriodos[i].segmento_id,
          serie_id: this.selectedPeriodos[i].id,
        };
        const data = await Turma.turmasAvancada(novo1);
        const novo2 = {
          etapaNome: this.selectedPeriodos[i].etapaNome,
          serieNome: this.selectedPeriodos[i].nome,
          turmasPorPeriodo: data.data,
        };
        this.turmasALL.push(novo2);
      }
    },
    async clear() {
      this.periodosALL = [];
    },
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0123456789]+$/.test(char)) {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },
    async buscarCep() {
      this.escola.cep = this.escola.cep.replace(" ", "");
      const cepTemp = this.escola.cep.replace("-", "").replace(" ", "");
      if (cepTemp.length == 8) {
        const data = await buscarCEP.searchCep(cepTemp);
        if (data["erro"]) {
          this.$vaToast.init({
            message: "CEP inválido!",
            iconClass: "fa-star-o",
            position: "button-left",
            color: "danger",
            duration: 2500,
            fullWidth: false,
          });
        } else {
          (this.escola.logradouro = data["logradouro"]),
            (this.escola.estado = data["uf"]),
            (this.escola.cidade = data["localidade"]),
            (this.escola.bairro = data["bairro"]),
            (this.cepValido = 1);
        }
      }
    },

    async editarOption(id) {
      try {
        const data = await Escola.obtemUm(id);
        this.escola.id = id;
        this.escola.cnpj = data.data.cnpj;
        this.escola.nome = data.data.nome;
        this.escola.cep = data.data.cep;
        this.escola.email = data.data.email;
        this.escola.codigo_INEP = data.data.codigo_INEP;
        this.escola.telefone = data.data.telefone;
        this.escola.numero = data.data.numero;
        this.escola.complemento = data.data.complemento;
        this.escola.bairro = data.data.bairro;
        this.escola.cidade = data.data.cidade;
        this.escola.estado = data.data.estado;
        this.escola.logradouro = data.data.logradouro;
      } catch (e) {
        // console.log(e);
      }
    },
    async editar() {
      let data;
      try {
        data = await Escola.alterar(this.escola);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        this.$router.push({ name: "escolas" });
        await this.listaTudo();
      } catch (e) {
        this.erro = 1;
        if (e.response.data.validacao) {
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          });
        } else {
          let a = [];
          a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a[0],
              iconClass: "fa-star-o",
              position: "bottom-left",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }

        /*this.$vaToast.init({
          message: "Erro ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })*/
      }
      //this.$router.push({name: 'escolas'});
    },
    habilitaredicao() {
      this.escolaHab = 1;
    },
  },
  async beforeMount() {
    if (this.idEscola != null) {
      this.st_idEscola = this.idEscola;
    } else {
      this.st_idEscola = sessionStorage.getItem("TurmaConfg_escola_id");
    }

    this.editarOption(this.st_idEscola);
    this.buscarCalendarioAtual();
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.listaTudo();
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>
<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}

.colegio-info .va-input_solid .va-input-wrapper__content .va-input__container {
  background-color: #ebebeb;
}

.colegio-info
  .va-input_solid
  .va-input-wrapper__content
  .va-input__container
  .va-input__content-wrapper
  .va-input__content
  .va-input__content__input {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000;
  opacity: 1;
  font-size: 18px;
}

.bdg-escola span {
  font-size: 13px;
  padding: 2px;
  border-radius: 70px;
}
</style>
